import React from "react";

function MobileList({
  locations,
  setShowMobileList,
  searchQuery,
  activeLocation,
  previewLocation,
}) {
  return (
    <div className="bg-white bg-opacity-90 pt-10 max-h-[60vh] overflow-y-scroll px-5 pb-2">
      <div className="w-full">
        <div className="flex justify-between p-2">
          <h1 className="font-semibold text-lg">
            {searchQuery ? "Search Results" : "Recent Locations"}
          </h1>
        </div>
        <ul className="flex flex-col space-y-2 mb-2">
          {locations
            .filter(
              (location) =>
                location.name.toLowerCase().includes(searchQuery) ||
                location.address.toLowerCase().includes(searchQuery)
            )
            .map((location) => (
              <li
                key={location._id}
                className={`${
                  location._id === activeLocation
                    ? "bg-gray-200 hover:bg-gray-300 shadow-lg"
                    : "hover:bg-gray-100"
                } flex flex-col py-2 cursor-pointer px-2 rounded-lg hover:shadow-lg`}
                onClick={() => {
                  setShowMobileList(false);
                  previewLocation(location._id, location.lat, location.lng);
                }}
              >
                <div className="font-semibold">
                  <p>{location.name}</p>
                </div>

                <div>
                  <p>({location.address})</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default MobileList;
