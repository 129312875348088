import { XMarkIcon } from "@heroicons/react/24/outline";
import { ClipLoader } from "react-spinners";

function AddNewLocationForm({
  showAddLocationForm,
  handleAddLocation,
  nameRef,
  addressRef,
  loading,
  setShowAddLocationForm,
}) {
  return (
    <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 shadow-xl fixed z-50 mx-auto inset-x-0 top-32 ">
      <div className="shadow-lg">
        <form
          className="mx-auto bg-white shadow-lg rounded-lg bg-opacity-95"
          onSubmit={handleAddLocation}
        >
          <div className="px-2 py-1 select-none flex justify-between">
            <h1 className="my-1 font-semibold">Add New Location</h1>
            <XMarkIcon className="w-5 h-5 cursor-pointer" onClick={() => setShowAddLocationForm(false)} />
          </div>
          <div className="p-2 px-5">
            <label htmlFor="companyName" className="text-sm">Name</label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              className="w-full py-2 px-3  border-b outline-none"
              autoFocus
              placeholder="Company Name"
              ref={nameRef}
            />
          </div>
          <div className="p-2 px-5">
            <label htmlFor="companyAddress" className="text-sm">Address</label>
            <input
              type="text"
              name="companyAddress"
              id="companyAddress"
              className="w-full py-2 px-3  border-b outline-none"
              placeholder="Company Address"
              ref={addressRef}
            />
          </div>

          <div className="w-full flex justify-end">
            <button className="w-32 p-2 shadow-md rounded-lg bg-gray-200 m-2 text-gray-700 font-semibold">
              {loading ? <ClipLoader size={18} color="#777" /> : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddNewLocationForm;
