import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import MobileList from "../list/MobileList";

function Header({
  showNames,
  setShowNames,
  setCurrentCoordinates,
  setMoreOptions,
  moreOptions,
  setSearchQuery,
  showMobileList,
  setShowMobileList,
  locations,
  searchQuery,
  activeLocation,
  setActiveLocation,
  previewLocation,
}) {
  const updateLocationToCurrent = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCurrentCoordinates({ lat: latitude, lng: longitude });
      }
    );
  };

  function handleToggleList() {
    setShowMobileList((prevState) => {
      return !prevState;
    });
  }

  return (
    <div className="fixed w-full z-30 bg-gray-300 bg-opacity-80 h-24 md:h-16 select-none">
      <nav className="md:flex justify-between px-3 pt-3 pb-2 max-w-7xl mx-auto items-center">
        <div>
          <h1 className="text-2xl font-bold text-teal-700">NavZor</h1>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setMoreOptions(!moreOptions)}
            className="header-button"
          >
            {moreOptions ? "Less" : "More"}
          </button>
          <button onClick={updateLocationToCurrent} className="header-button">
            My Location
          </button>
          <button
            onClick={() => setShowNames(!showNames)}
            className="header-button"
          >
            {showNames ? "Hide Names" : "Show Names"}
          </button>
          <input
            type="search"
            name="location_search"
            id="location_search"
            placeholder="Search Locations..."
            className="p-2 border rounded-lg outline-offset-1"
            onChange={(e) => {
              setSearchQuery(e.target.value.toLowerCase());

              if (e.target.value.length > 0) {
                setShowMobileList(true);
              } else {
                setShowMobileList(false);
              }
            }}
          />
        </div>
      </nav>
      <div className="lg:hidden">
        <div className="flex items-center space-x-3 absolute ml-5 -mt-2 sm:-mt-5 md:mt-0">
          <div
            className="lg:hidden p-3  bg-white rounded-full text-gray-500 bg-opacity-90 shadow-lg"
            onClick={handleToggleList}
          >
            {showMobileList ? (
              <div className="bg-opacity-100">
                <ChevronUpIcon className="w-7 h-7" />
              </div>
            ) : (
              <div>
                <ChevronDownIcon className="w-7 h-7" />
              </div>
            )}
          </div>
        </div>
        {showMobileList && (
          <MobileList
            showMobileList={showMobileList}
            setShowMobileList={setShowMobileList}
            locations={locations}
            searchQuery={searchQuery}
            activeLocation={activeLocation}
            setActiveLocation={setActiveLocation}
            previewLocation={previewLocation}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
