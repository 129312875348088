import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

function List({
  locations,
  activeLocation,
  searchQuery,
  previewLocation,
}) {
  const [listInView, setListInView] = useState(true);

  function handleToggleList() {
    setListInView((prevState) => {
      return !prevState;
    });
  }

  return (
    <div>
      <div
        className={`bg-white bg-opacity-90 max-h-[80vh] overflow-y-scroll z-10 px-5 ${
          !listInView
            ? "-translate-x-full ml-10 transition duration-500 ease-in-out"
            : "translate-x-0 transition duration-500 ease-in-out"
        }`}
      >
        <div className={`hidden lg:block w-full`}>
          <div className="flex justify-between py-2">
            <h1 className="font-semibold text-lg">
              {searchQuery ? "Search Results" : "Recent Locations"}
            </h1>
            {listInView ? (
              <button onClick={handleToggleList}>
                <ChevronLeftIcon className="w-5 h-5" />
              </button>
            ) : (
              <button onClick={handleToggleList}>
                <ChevronRightIcon className="w-5 h-5" />
              </button>
            )}
          </div>
          <ul className="flex flex-col space-y-2">
            {locations
              .filter(
                (location) =>
                  location.name.toLowerCase().includes(searchQuery) ||
                  location.address.toLowerCase().includes(searchQuery)
              )
              .map((location) => (
                <li
                  key={location._id}
                  className={`${
                    location._id === activeLocation
                      ? "bg-gray-200 hover:bg-gray-300 shadow-lg"
                      : "hover:bg-gray-100"
                  } flex flex-col py-2 cursor-pointer px-2 rounded-lg hover:shadow-lg`}
                  onClick={() =>
                    previewLocation(location._id, location.lat, location.lng)
                  }
                >
                  <div className="font-semibold">
                    <p>{location.name}</p>
                  </div>

                  <div>
                    <p>({location.address})</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default List;
