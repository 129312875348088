import { FaMapMarkerAlt } from "react-icons/fa";

function LocationMarker({
  name,
  active,
  showNames,
  temporary,
  setShowAddLocationForm,
}) {
  return (
    <div className="flex flex-col-reverse absolute -translate-x-[50%] -translate-y-[100%]">
      <FaMapMarkerAlt
        className={`${temporary ? "text-green-500" : "text-red-500"} ${
          active ? "w-10 h-10 text-purple-500" : ""
        } w-5 h-5`}
        title={name}
      />
      {temporary && (
        <div className="absolute -top-16 -right-11 w-28 text-center">
          <button
            onClick={() => setShowAddLocationForm(true)}
            className="text-blue-500 bg-gray-200 bg-opacity-70 shadow-md border p-1 rounded-md text-base"
          >
            Add New Location
          </button>
        </div>
      )}
      <p
        className={`${!showNames && "hidden"} ${
          active ? "font-bold" : ""
        } fixed bottom-6 font-semibold bg-gray-200 bg-opacity-70 border shadow-md text-md w-fit py-1 px-2 -translate-x-1/3 mb-1`}
      >
        {name}
      </p>
    </div>
  );
}

export default LocationMarker;

// const MapMarker = ({
//   name,
//   active,
//   showNames,
//   temporary,
//   setShowAddLocationForm,
// }) => (
//   <div className="flex flex-col-reverse absolute -translate-x-[50%] -translate-y-[100%]">
//     <FaMapMarkerAlt
//       className={`${temporary ? "text-green-500" : "text-red-500"} ${
//         active ? "w-10 h-10 text-purple-500" : ""
//       } w-5 h-5`}
//       title={name}
//     />
//     {temporary && (
//       <div className="absolute -top-16 -right-11 w-28 text-center">
//         <button
//           onClick={() => setShowAddLocationForm(true)}
//           className="text-blue-500 bg-gray-200 bg-opacity-70 shadow-md border p-1 rounded-md text-base"
//         >
//           Add New Location
//         </button>
//       </div>
//     )}
//     <p
//       className={`${!showNames && "hidden"} ${
//         active ? "font-bold" : ""
//       } fixed bottom-6 font-semibold bg-gray-200 bg-opacity-70 border shadow-md text-md w-fit py-1 px-2 -translate-x-1/3 mb-1`}
//     >
//       {name}
//     </p>
//   </div>
// );
