// https://react-icons.github.io/react-icons/icons?name=fa
import GoogleMapReact from "google-map-react";
import { useRef, useState } from "react";

import AddNewLocationForm from "../forms/AddNewLocationForm";
import LocationMarker from "./LocationMarker";

function Map({
  currentCoordinates,
  setCurrentCoordinates,
  locations,
  setLocations,
  activeLocation,
  setActiveLocation,
  showNames,
  moreOptions,
  searchQuery,
  setShowDetailsModal,
  setDetailedLocation,
}) {
  const centerCoordinates = { lat: 34.698007, lng: 33.039762 };
  const [loading, setLoading] = useState(false);
  const [addingMarker, setAddingMarker] = useState(false);
  const [temporaryMarker, setTemporaryMarker] = useState({});
  const [addingTemporaryMarker, setAddingTemporaryMarker] = useState(false);
  const [showAddLocationForm, setShowAddLocationForm] = useState(false);

  const nameRef = useRef();
  const addressRef = useRef();

  function handleMapClick(e) {
    if (!addingMarker) {
      setAddingMarker(true);
      setTimeout(() => {
        setAddingMarker(false);
      }, 500);
    } else {
      if (addingTemporaryMarker) {
        setAddingTemporaryMarker(false);
        setTemporaryMarker({});
      } else {
        setAddingTemporaryMarker(true);
        setTemporaryMarker({ lat: e.lat, lng: e.lng });
      }
    }
    
    setActiveLocation(null);

  }

  async function handleAddLocation(e) {
    e.preventDefault();
    setLoading(true);

    const newLocation = {
      name: nameRef.current.value,
      address: addressRef.current.value,
      lat: temporaryMarker.lat,
      lng: temporaryMarker.lng,
    };

    try {
      const res = await fetch("https://api.marianoprisan.com/locations/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newLocation),
      });

      if (res.ok) {
        const newLocationObject = await res.json();
        setLocations([...locations, newLocationObject]);
      } else {
        alert("Could not add location. Please try again");
      }
      
    } catch (error) {
      alert(error);
    }

    setLoading(false);

    setShowAddLocationForm(false);
    setTemporaryMarker({});
    setAddingTemporaryMarker(false);
  }

  return (
    <div className="h-full w-full" id="map">
      {showAddLocationForm && (
        <AddNewLocationForm
          handleAddLocation={handleAddLocation}
          nameRef={nameRef}
          addressRef={addressRef}
          loading={loading}
          setShowAddLocationForm={setShowAddLocationForm}
        />
      )}

      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={centerCoordinates}
        center={currentCoordinates}
        defaultZoom={12}
        yesIWantToUseGoogleMapApiInternals
        margin={[50, 50, 50, 50]}
        options={{
          disableDoubleClickZoom: true,
          fullscreenControlOptions: { position: 9 },
          styles: moreOptions
            ? [
                { featureType: "all", stylers: [{ visibility: "on" }] },
                {
                  featureType: "transit.station.bus",
                  stylers: [{ visibility: "off" }],
                },
              ]
            : [
                { featureType: "transit", stylers: [{ visibility: "off" }] },
                { featureType: "poi", stylers: [{ visibility: "off" }] },
                { featureType: "poi.school", stylers: [{ visibility: "on" }] },
              ],
        }}
        onChange={(e) => {
          setCurrentCoordinates({
            lat: e.center.lat,
            lng: e.center.lng,
          });
        }}
        onChildClick={(e) => {
          const detailed = locations.filter(location => location._id === e)
          setDetailedLocation(detailed[0])
          setShowDetailsModal(true);
        }}
        onClick={handleMapClick}
      >
        {locations
          .filter(
            (location) =>
              location.name.toLowerCase().includes(searchQuery) ||
              location.address.toLowerCase().includes(searchQuery)
          )
          .map((location) => (
            <LocationMarker
              key={location._id}
              lat={location.lat}
              lng={location.lng}
              name={location.name}
              active={location._id === activeLocation}
              showNames={showNames}
              temporary={false}
            />
          ))}

        {addingTemporaryMarker && (
          <LocationMarker
            lat={temporaryMarker?.lat}
            lng={temporaryMarker?.lng}
            setShowAddLocationForm={setShowAddLocationForm}
            temporary={true}
          />
        )}
      </GoogleMapReact>
    </div>
  );
}

export default Map;
