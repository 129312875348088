import {
  XMarkIcon,
  ArrowTopRightOnSquareIcon,
  ArrowDownOnSquareStackIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

function DetailsModal({
  setShowDetailsModal,
  detailedLocation,
  loading,
  handleLocationDelete,
}) {
  const [maximized, setMaximized] = useState(false);

  const toggleMaximizeDetailsModal = () => {
    setMaximized((prevState) => !prevState);
  };

  return (
    <div
      id="details-overlay"
      className="absolute flex justify-center items-center w-full h-screen bg-gray-900 z-40 bg-opacity-30 cursor-pointer"
      onClick={(e) => {
        if (e.target.id === "details-overlay") {
          setShowDetailsModal(false);
        }
      }}
    >
      <div
        className={`bg-white rounded-lg bg-opacity-95 shadow-lg cursor-default ${
          maximized ? "w-5/6 md:w-3/6 h-2/3" : "w-2/3 md:w-2/5 max-w-md h-1/3"
        } transition-all duration-300 ease-linear`}
        onClick={() => {}}
      >
        <div className="h-full flex flex-col justify-between">
          <div className="flex flex-col px-5">
            <div className="flex space-x-3 justify-end p-2">
              {maximized ? (
                <ArrowDownOnSquareStackIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={toggleMaximizeDetailsModal}
                />
              ) : (
                <ArrowTopRightOnSquareIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={toggleMaximizeDetailsModal}
                />
              )}
              <XMarkIcon
                className="w-5 h-5 cursor-pointer"
                onClick={() => setShowDetailsModal(false)}
              />
            </div>
            <p className="text-xs font-semibold">Name</p>
            <p className="">{detailedLocation.name}</p>
            <div className="flex flex-col overflow-y-scroll">
              <p className="text-xs font-semibold">Address</p>
              <p className="">{detailedLocation.address}</p>
            </div>
          </div>
          <div
            className={`flex ${maximized ? "justify-between" : "justify-end"}`}
          >
            {maximized && (
              <button
                className="text-red-800 font-semibold ml-5 hover:underline"
                onClick={() => {
                  if(window.confirm("Are you sure?")) {
                    handleLocationDelete(detailedLocation._id)
                  }
                }}
              >
                Remove this location
              </button>
            )}
            <button
              className="w-32 p-2 shadow-md rounded-lg bg-gray-200 m-2 text-gray-700 font-semibold"
              disabled={loading}
              onClick={() => {
                setShowDetailsModal(false);
              }}
            >
              {loading ? <ClipLoader size={18} color="#777" /> : "Ok"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsModal;
