import { useEffect, useState } from "react";
import DetailsModal from "./components/details/DetailsModal";
import Header from "./components/header/Header";
import List from "./components/list/List";

import Map from "./components/map/Map";

import "./index.css";

function App() {
  const [currentCoordinates, setCurrentCoordinates] = useState({});
  const [locations, setLocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState(null);

  const [showNames, setShowNames] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [moreOptions, setMoreOptions] = useState(false);

  const [showMobileList, setShowMobileList] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [detailedLocation, setDetailedLocation] = useState({});

  const previewLocation = (id, lat, lng) => {
    if (activeLocation !== id) {
      setActiveLocation(id);
      setCurrentCoordinates({ lat, lng });
    } else {
      setActiveLocation(null);
    }
  };

  const handleLocationDelete = async (locationId) => {
    try {
      fetch("https://api.marianoprisan.com/locations/" + locationId, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((res) => {
          setLocations((prevLocations) =>
            prevLocations.filter((location) => location._id !== locationId)
          );
          setShowDetailsModal(false);
        });
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    setCurrentCoordinates({ lat: 34.681984410449, lng: 33.042993874687035 });

    const getLocations = async () => {
      try {
        fetch("https://api.marianoprisan.com/locations")
          .then((res) => res.json())
          .then((data) => {
            setLocations(data);
            setDetailedLocation(data[0]);
          });
      } catch (error) {
        alert(error);
      }
    };

    getLocations();
  }, []);

  return (
    <div className="h-screen">
      <div className="">
        <Header
          setMoreOptions={setMoreOptions}
          showNames={showNames}
          setShowNames={setShowNames}
          setCurrentCoordinates={setCurrentCoordinates}
          moreOptions={moreOptions}
          locations={locations}
          setLocations={setLocations}
          setSearchQuery={setSearchQuery}
          showMobileList={showMobileList}
          setShowMobileList={setShowMobileList}
          searchQuery={searchQuery}
          activeLocation={activeLocation}
          setActiveLocation={setActiveLocation}
          previewLocation={previewLocation}
        />
      </div>
      <div className="h-full flex flex-col lg:flex-row">
        <div className="lg:w-2/5 xl:w-1/3 p-2 absolute left-0 z-30 bg-transparent mt-16 lg:mt-28">
          <List
            setCurrentCoordinates={setCurrentCoordinates}
            locations={locations}
            activeLocation={activeLocation}
            setActiveLocation={setActiveLocation}
            searchQuery={searchQuery}
            previewLocation={previewLocation}
          />
        </div>

        {showDetailsModal && (
          <DetailsModal
            setShowDetailsModal={setShowDetailsModal}
            detailedLocation={detailedLocation}
            handleLocationDelete={handleLocationDelete}
          />
        )}

        <div className="w-full h-full">
          <Map
            currentCoordinates={currentCoordinates}
            setCurrentCoordinates={setCurrentCoordinates}
            locations={locations}
            setLocations={setLocations}
            activeLocation={activeLocation}
            showNames={showNames}
            moreOptions={moreOptions}
            searchQuery={searchQuery}
            previewLocation={previewLocation}
            showDetailsModal={showDetailsModal}
            setShowDetailsModal={setShowDetailsModal}
            setDetailedLocation={setDetailedLocation}
            setActiveLocation={setActiveLocation}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
